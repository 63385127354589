import React, {useEffect, useState} from "react"
import {Header} from "../common/Header";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import {connect, useDispatch, useSelector} from "react-redux"
import "react-datepicker/dist/react-datepicker.css";
import {ContentWrapper} from "../common/ContentWrapper";
import {navigate} from "gatsby";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import {CategoryHeader} from "../common/CategoryHeader";
import {
	houseOwnershipTypeData,
	economicActivityData,
	areaPartForEconomicActivityData,
	propertyPermanentlyInhabitedData,
	economicAcitvityTypeData,
	flatOwnershipTypeData,
	propertyInConstructionOwnershipTypeData, ownershipTypeTooltip,
} from "../../dataSources/Page2DataSource";
import {Slide} from "react-reveal";
import {
	BlueHint, ErrorMessageText,
	InvisibleWrapper, RadioButtonsTwoOptionsWrapper,
	TabWrapper
} from "../sharedComponents/SharedComponents";
import {Field, formValueSelector, reduxForm} from "redux-form";
import CustomSelectReduxForm from "../commonReduxFormComponents/CustomSelectReduxForm";
import SingleTextInput from "../commonReduxFormComponents/SingleTextInput";
import SingleRadioButtonTwoOptionsReduxForm from "../commonReduxFormComponents/SingleRadioButtonTwoOptionsReduxForm";
import {yesOrNo} from "../../dataSources/CommonDataSource";
import {sendDataToFortWebApiAction} from "../../state/sendDataToFortWebApi";

let CleanSecondStep = ({handleSubmit, value, load, pristine, reset, submitting, houseType,
	                       roofType, wallsConstructionType, propertyForRent, propertyArea, buildYearLabel,
	                       damageAmountLastYear, propertyType, change, city, economicActivity, assignmentToBank}) => {
	const dispatch = useDispatch()
	const formReducer = useSelector(state => state.form)

	const [ownershipTypeTooltipVisible, toogleOwnershipTypeVisibility] = useState(false)
	const handleOwnershipTypeTooltipVisibility = () => {
		toogleOwnershipTypeVisibility(!ownershipTypeTooltipVisible)
	}

	return (
		<TabWrapper>
			<Header className="tablet-hidden">Szczegóły</Header>
			<BlueHint className="tablet-hidden">wypełnij formularz</BlueHint>
			<ContentWrapper className="smaller">
				<form onSubmit={handleSubmit}>
					<InvisibleWrapper isVisible={propertyType === "Dom" || propertyType === "Gospodarstwo rolne"}>
						<Field
							name="home[houseOwnershipType]"
							component={CustomSelectReduxForm}
							type="text"
							options={houseOwnershipTypeData}
							// validate={validate.isRequired}
							selectLabel={"Forma własności"}
							placeholder={"Wybierz formę własności"}
							isFirst={true}
							showCircle={true}
							isFirstOnPage={true}
							isWindowVisible={ownershipTypeTooltipVisible}
							onClick={handleOwnershipTypeTooltipVisibility}
							tooltipHeader={ownershipTypeTooltip.header}
							tooltipDescription={ownershipTypeTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Dom w budowie"}>
						<Field
							name="home[houseOwnershipType]"
							component={CustomSelectReduxForm}
							type="text"
							options={propertyInConstructionOwnershipTypeData}
							// validate={validate.isRequired}
							selectLabel={"Forma własności"}
							placeholder={"Wybierz formę własności"}
							isFirst={true}
							isFirstOnPage={true}
							showCircle={true}
							isWindowVisible={ownershipTypeTooltipVisible}
							onClick={handleOwnershipTypeTooltipVisibility}
							tooltipHeader={ownershipTypeTooltip.header}
							tooltipDescription={ownershipTypeTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Mieszkanie"}>
						<Field
							name="home[flatOwnershipType]"
							component={CustomSelectReduxForm}
							type="text"
							options={flatOwnershipTypeData}
							// validate={validate.isRequired}
							selectLabel={"Forma własności"}
							placeholder={"Wybierz formę własności"}
							isFirst={true}
							isFirstOnPage={true}
							showCircle={true}
							isWindowVisible={ownershipTypeTooltipVisible}
							onClick={handleOwnershipTypeTooltipVisibility}
							tooltipHeader={ownershipTypeTooltip.header}
							tooltipDescription={ownershipTypeTooltip.description}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Gospodarstwo rolne"}>
						<Field
							name="home[farmSize]"
							component={SingleTextInput}
							type="number"
							unitName="ha"
							// // validate={validate.isRequired}
							editableInputHeader={"Wielkość gospodarstwa rolnego"}
							placeholder={"Wpisz powierzchnię"}
						/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType !== "Dom w budowie"}>
					<Field
						name="home[propertyPermanentlyInhabited]"
						component={CustomSelectReduxForm}
						type="text"
						options={propertyPermanentlyInhabitedData}
						// validate={validate.isRequired}
						selectLabel={"Czy budynek jest zamieszkany?"}
						placeholder={"Wybierz właściwą opcję"}
					/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Mieszkanie"}>
					<Field
						name="home[economicActivity]"
						component={CustomSelectReduxForm}
						type="text"
						options={economicActivityData}
						// validate={validate.isRequired}
						selectLabel={"Czy w lokalu jest wykonywana działalność gospodarcza?"}
						placeholder={"Wybierz wariant"}
					/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Dom" || propertyType === "Gospodarstwo rolne"}>
					<Field
						name="home[economicActivity]"
						component={CustomSelectReduxForm}
						type="text"
						options={economicActivityData}
						// validate={validate.isRequired}
						selectLabel={"Czy w nieruchomości jest wykonywana działalność gospodarcza?"}
						placeholder={"Wybierz wariant"}
					/>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={ economicActivity?.value === 1}><Slide top duration="300">
						<Field
							name="home[areaPartForEconomicActivity]"
							component={CustomSelectReduxForm}
							type="text"
							options={areaPartForEconomicActivityData}
							// validate={validate.isRequired}
							selectLabel={"Jak duża część powierzchni przeznaczona jest na działalność gospodarczą?"}
							placeholder={"Wybierz część powierzchni"}
						/>
						<Field
							name="home[economicActivityType]"
							component={CustomSelectReduxForm}
							type="text"
							options={economicAcitvityTypeData}
							// validate={validate.isRequired}
							selectLabel={"Rodzaj wykonywanej działalności"}
							placeholder={"Wybierz działalność"}
						/>
					</Slide>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Dom" || propertyType === "Gospodarstwo rolne"}>
					<CategoryHeader className="margin-top">Czy nieruchomość będzie wynajmowana innym osobom?</CategoryHeader>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType === "Mieszkanie"}>
					<CategoryHeader className="margin-top">Czy lokal mieszkalny będzie wynajmowana innym osobom?</CategoryHeader>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={propertyType !== "Dom w budowie"}>
					<RadioButtonsTwoOptionsWrapper>
						{yesOrNo.map(({label, value}) => {
							const tempId = value === true ? 1 : value === false ? 2 : 0;
							return (
								<Field name="home[propertyForRent]"
								       id={tempId}
								       key={tempId}
								       htmlId={'property-for-rent-' + tempId}
								       type="radio"
								       component={SingleRadioButtonTwoOptionsReduxForm}
								       amountName={label}
								       activeId={propertyForRent}
								       isSmallMargin={false}
								       onClick={(val) => {
								       	console.log(val)
									       if(val === 1) {
										       change('home[propertyForRent]', true)
									       } else if (val === 2) {
										       change('home[propertyForRent]', false)
									       }
								       }}/>
							)})}
					</RadioButtonsTwoOptionsWrapper>
					</InvisibleWrapper>



							<NavigationButtonsWrapper>
								<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"} to="/"/>
								<NextStepButton type="submit"
								                isDisabled={submitting}
								                isVisible={true} textBtn={"DALEJ"}/>
							</NavigationButtonsWrapper>


						</form>
			</ContentWrapper>
		</TabWrapper>
	)
};

const validate = values => {
	const errors = {
		"lead": {},
		"person": {},
		"home": {
			"address": {},
		},
	};
	if (values?.home?.propertyType === "Dom" || values?.home?.propertyType === "Dom w budowie" || values?.home?.propertyType === "Gospodarstwo rolne") {
		if (!values?.home?.houseOwnershipType) {
			errors.home.houseOwnershipType = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType === "Mieszkanie") {
		if (!values?.home?.flatOwnershipType) {
			errors.home.flatOwnershipType = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType === "Gospodarstwo rolne") {
		if (!values?.home?.farmSize) {
			errors.home.farmSize = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType !== "Dom w budowie") {
		if (!values?.home?.propertyPermanentlyInhabited) {
			errors.home.propertyPermanentlyInhabited = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType !== "Dom w budowie") {
		if (!values?.home?.economicActivity) {
			errors.home.economicActivity = 'pole wymagane';
		}
	}



	if (values?.economicActivity?.value === 1) {
		if (!values?.home?.areaPartForEconomicActivity) {
			errors.home.areaPartForEconomicActivity = 'pole wymagane';
		}
		if (!values?.home?.economicActivityType) {
			errors.home.economicActivityType = 'pole wymagane';
		}
	}
	if (values?.home?.propertyType !== "Dom w budowie") {
		if (values?.home?.propertyForRent === null) {
			console.log(values.home.propertyForRent);
			errors.home.propertyForRent = 'pole wymagane';
		}
	}

	// if (!values.assignmentToBank) {
	// 	errors.assignmentToBank = 'pole wymagane';
	// }
	console.log(values);
	console.log(errors);
	console.log('page2blank ' + JSON.stringify(errors))
	return errors;
};

const onSubmit = (values, dispatch, formReducer) => {
	localStorage.setItem('propertyForm', JSON.stringify(values));
	// registerField('propertyForm', 'street', 'string')
	// localStorage.setItem('form-data', JSON.parse(values));
	// alert(JSON.stringify(values))
	// alert(JSON.stringify(formReducer))
	// console.log(formReducer)
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(values)))
	// dispatch(togglePopupVisibility(true))
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(formReducer.values)))
	dispatch(sendDataToFortWebApiAction(values))
	navigate('/step-3')
	// console.log(JSON.stringify(values))
}

CleanSecondStep = reduxForm({
	form: 'propertyForm',
	destroyOnUnmount: false,
	keepDirtyOnReinitialize: true,
	// forceUnregisterOnUnmount: true,
	validate,
	onSubmit
})(CleanSecondStep);

const selector = formValueSelector('propertyForm')
CleanSecondStep = connect(state => {
	// can select values individually
	const propertyForRent = selector(state, 'home[propertyForRent]')
	const propertyArea = selector(state, 'home[propertyArea]')
	const damageAmountLastYear = selector(state, 'home[damageAmountLastYear]')
	const propertyType = selector(state, 'home[propertyType]')
	const houseType = selector(state, 'home[houseType]')
	const roofType = selector(state, 'home[roofType]')
	const wallsConstructionType = selector(state, 'home[wallsConstructionType]')
	const buildYearLabel = selector(state, 'buildYearLabel')
	const city = selector(state, 'home[address[city]]')
	const economicActivity = selector(state, 'home[economicActivity]')
	// const assignmentToBank = selector(state, 'assignmentToBank')
	// values: getFormValues('propertyForm')(state)
	// const favoriteColorValue = selector(state, 'favoriteColor')
	// or together as a group
	// const { firstName, lastName } = selector(state, 'firstName', 'lastName')
	return {
		propertyForRent,
		propertyArea,
		damageAmountLastYear,
		propertyType,
		houseType,
		roofType,
		wallsConstructionType,
		buildYearLabel,
		city,
		economicActivity,
		// assignmentToBank,
		// favoriteColorValue,
		// fullName: `${firstName || ''} ${lastName || ''}`
	}
})(CleanSecondStep)

export default CleanSecondStep;

