import React from "react";
import {useSelector} from "react-redux";
import CleanSecondStep from "../components/secondStep/CleanSecondStep";

const Step2 = () => {
	const isDataLoadFromLocalStorage = useSelector(state => state.loadDataFromLocalStorage.loadDataFromLocalstorage);
	return (
		<>
			<CleanSecondStep/>
		</>
	)
};

export default Step2
